import React, { memo } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Query, QueryUserArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggUserPopover } from 'src/components/domain/users/components/lgg-user-popover';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const USER_QUERY = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      fullName
      isActive
      role {
        id
        name
      }
      avatar {
        color
        initials
      }
    }
  }
`;

type InternalNoteMentionProps = {
  userId: string;
  label: string;
};

const UserTag = styled.span<{ active: boolean }>`
  display: inline-block;

  ${({ active, theme }) => {
    if (active) {
      return `
      cursor: pointer;
      color: ${theme.colors.gogo};
      font-family: ${theme.font.medium};
    `;
    }
  }}
`;

export const InternalNoteMention = memo<InternalNoteMentionProps>(({ userId, label }) => {
  const handleGraphQlError = useHandleGraphQLError();
  const { data: userData, loading } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(
    USER_QUERY,
    {
      variables: {
        id: userId.toString(),
      },
      onError: handleGraphQlError,
    },
  );

  const isActive = !loading && userData!.user.isActive;

  const userTag = (
    <UserTag active={isActive} data-lgg-id={`internal-note-mention-${userId}`}>
      {label}
    </UserTag>
  );

  return isActive ? (
    <LggUserPopover user={userData?.user} loading={loading}>
      {userTag}
    </LggUserPopover>
  ) : (
    userTag
  );
});
